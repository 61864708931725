import Queue from 'async-es/queue';
import heic2any from 'heic2any';
import { MultipartUploadService } from './multipart-upload-service';

class FileService {
    fileQueue;
    fileCache = new Set();

    constructor(maxThreads) {
        this.fileQueue = Queue(({ id, file }, callback) => {
            this.__uploadFile({ id, file }).then(callback);
        }, maxThreads);
    }

    async uploadFile({ id, file }) {
        if (this.fileCache.has(id)) {
            return Promise.reject('File has already been uploaded');
        }
        this.fileCache.add(id);
        return new Promise((resolve) => {
            this.fileQueue.push({ file }, resolve);
        });
    }

    async __uploadFile({ file }) {
        if (file.type === 'image/heic') {
            file = await heic2any({ blob: file });
        }

        const uploadService = new MultipartUploadService(file);
        await uploadService.upload();
        return 'Success';
    }
}

const fileService = new FileService(5);
export default fileService;
