import { usePromise } from '../../hooks/use-promise';

export function UploadFile({
    id,
    file,
    uploadPromise,
    onRemoveFile = (id) => {},
}) {
    const { complete, result, error } = usePromise(uploadPromise);
    let uploadStatus = '';
    if (uploadPromise) {
        if (complete && result) {
            uploadStatus = 'Upload successful';
        } else if (complete && error) {
            uploadStatus = error;
        } else {
            uploadStatus = 'Uploading';
        }
    } else {
        uploadStatus = 'Ready for upload';
    }
    return (
        <>
            <td className="is-vcentered">{file.name}</td>
            <td className="is-vcentered">{uploadStatus}</td>
            <td className="is-vcentered">
                <button className="icon" onClick={() => onRemoveFile(id)}>
                    <i className="fa-solid fa-xmark" />
                </button>
            </td>
        </>
    );
}
