import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Header } from './header';
import { Upload } from './upload/upload';

function PageWrapper({ children }) {
    return (
        <div>
            <Header />
            {children}
        </div>
    );
}

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <PageWrapper>
                <div>
                    Hello world
                    <video controls>
                        <source
                            type="video/mp4"
                            src="https://tracy-project.goodmanson.me/api/image/4c61ec1a-07f0-4119-a5e1-01f3096a8b53"
                        />
                    </video>
                </div>
            </PageWrapper>
        ),
    },
    {
        path: '/upload',
        element: (
            <PageWrapper>
                <Upload />
            </PageWrapper>
        ),
    },
]);

export function Router() {
    return <RouterProvider router={router} />;
}
