import { useState } from 'react';
import { UploadFile } from './upload-file';
import FileService from '../../service/file-service';

export function Upload() {
    const [files, setFiles] = useState([]);
    const handleChange = (event) => {
        const updatedFiles = Array.from(event.target.files).map((file) => ({
            id: crypto.randomUUID(),
            file,
            uploadStatus: 'Ready for upload',
        }));
        setFiles([...files, ...updatedFiles]);
    };
    const handleRemoveFile = (id) => {
        const updatedFiles = files.filter((file) => file.id !== id);
        setFiles(updatedFiles);
    };
    const handleUploadClick = () => {
        const updatedFiles = files.map((file) => {
            const uploadPromise = FileService.uploadFile(file);
            return {
                ...file,
                uploadPromise,
            };
        });

        setFiles(updatedFiles);
    };
    return (
        <div className="block">
            upload
            <div className="file">
                <label className="file-label">
                    <input
                        className="file-input"
                        type="file"
                        name="uploads"
                        accept="image/*,image/heic,video/*"
                        multiple
                        onChange={handleChange}
                    />
                    <span className="file-cta">
                        <span className="file-icon">
                            <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">Choose a file…</span>
                    </span>
                </label>
            </div>
            <button
                className="button is-primary"
                onClick={() => handleUploadClick()}
            >
                Upload
            </button>
            {files.length > 0 && (
                <table className="table is-striped">
                    <thead>
                        <tr>
                            <th>File name</th>
                            <th>Status</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((file) => (
                            <tr key={file.id}>
                                <UploadFile
                                    id={file.id}
                                    file={file.file}
                                    uploadPromise={file.uploadPromise}
                                    onRemoveFile={(id) => handleRemoveFile(id)}
                                />
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}
