import './App.css';
import { Router } from './component/router';

function App() {
    return (
        <div>
            <Router />
        </div>
    );
}

export default App;
