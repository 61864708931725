import Queue from 'async-es/queue';

export class MultipartUploadService {
    /**
     * Create an instance of this service to perform multipart uploads
     * @param {File} file
     * @param {number} maxPartSize - must be at least 10MB
     */
    constructor(file, maxPartSize = 10000000) {
        this.file = file;
        this.maxPartSize = Math.max(maxPartSize, 10000000);
    }

    /**
     * Uploads the file
     * @returns {Promise<void>}
     */
    async upload() {
        const url = 'https://tracy-project.goodmanson.me/api/upload';
        const startUploadUrl = new URL(url);
        startUploadUrl.searchParams.set('action', 'mpu-create');
        const { key, uploadId } = await fetch(startUploadUrl, {
            method: 'POST',
        }).then((response) => response.json());

        const parts = await this.__getParts();

        const queue = Queue(({ filePart, partNumber }, callback) => {
            const uploadPartUrl = new URL(url);
            uploadPartUrl.searchParams.set('key', key);
            uploadPartUrl.searchParams.set('uploadId', uploadId);
            uploadPartUrl.searchParams.set('partNumber', partNumber);

            fetch(uploadPartUrl, {
                method: 'PUT',
                body: filePart,
            })
                .then((response) => response.json())
                .then(callback);
        }, 5);

        const uploadedParts = [];
        parts.forEach((filePart, index) =>
            queue.push({ filePart, partNumber: index + 1 }, (response) =>
                uploadedParts.push(response),
            ),
        );

        await queue.drain();

        const endUploadUrl = new URL(url);
        endUploadUrl.searchParams.set('action', 'mpu-complete');
        endUploadUrl.searchParams.set('uploadId', uploadId);
        endUploadUrl.searchParams.set('key', key);

        return fetch(endUploadUrl, {
            method: 'POST',
            body: JSON.stringify({
                parts: uploadedParts,
            }),
        });
    }

    /**
     * Divide file into parts
     * @returns {Promise<string[]>}
     * @private
     */
    async __getParts() {
        const numberOfParts = Math.floor(this.file.size / this.maxPartSize);
        const remainder = this.file.size % this.maxPartSize;
        const parts = [];
        for (let i = 0; i < numberOfParts; i++) {
            const start = this.maxPartSize * i;
            const part = await this.file
                .slice(start, start + this.maxPartSize)
                .text();
            parts.push(part);
        }

        if (remainder > 0) {
            const part = await this.file.slice(-remainder).text();
            parts.push(part);
        }

        return parts;
    }
}
