import { useEffect, useState } from 'react';

export function usePromise(promise) {
    const [state, setState] = useState({
        complete: false,
        result: null,
        error: null,
    });

    useEffect(() => {
        if (!promise) {
            return;
        }
        promise
            .then((result) =>
                setState({
                    complete: true,
                    result,
                    error: null,
                }),
            )
            .catch((error) =>
                setState({
                    complete: true,
                    result: null,
                    error: error,
                }),
            );
    }, [promise]);

    return state;
}
